<template>
  <div class="medical-test">
    <i class="arrow"
       @click="$router.back(-1)"></i>
    <h2>中医体质测试</h2>
    <!-- <button @click="randomChoose"> random choose</button> -->
    <div class="content">
      <div class="item"
           v-for="(item,index) in testItems"
           :key="'topic'+ index"
           :ref="'item'+ index">
        <h3>{{index+1}}. {{item.question_text}}</h3>
        <div class="select-wrap">
          <span :class="choosenIndex[index] == answerIndex? 'active select': 'select'"
                v-for="(answer,answerIndex) in item.answers"
                :key="'answer'+ index + answer.id"
                @click="clickAnswer(answer, item, index, answerIndex)">{{answer.answer_name}} </span>
        </div>
      </div>
    </div>
    <div class="btn start"
         @click="submitAnswer()">确定</div>
  </div>
</template>
<script>
import { apiUrl, http } from "../config";
export default {
  name: 'medicaltest',
  components: {
  },
  data () {
    return {
      name: '',
      physique_name: '',
      testItems: [],
      selectedItems: [],
      choosenIndex: [],
      answerid: ''
    }
  },

  mounted () {
    this.get();
  },
  methods: {
    get () {
      let query = this.$route.query;
      let applyid = query.applyid
      let idcard = query.idcard
      let url = `/cmpa/questionnaire`;
      http.get(url, { params: { applyid: applyid, idcard: idcard } })
        .then((res) => {
          res.data.map((item) => {
            this.selectedItems.push(null);
            this.choosenIndex.push(null);
            this.testItems.push(item);
          });
          this.physique_name = res.data.physique_name;
        });
    },
    reset () {
      this.physique_name = '';
      this.testItems = [];
      this.selectedItems = [];
      this.choosenIndex = [];
    },
    randomChoose () {
      this.testItems.forEach((item, index, array) => {
        let answers = item.answers
        let answerIndex = Math.ceil(Math.random() * 3);
        let answer = answers[answerIndex]
        let physique_id = item.physique_id;
        let question_id = item.id;
        let answer_id = answer.id;
        let answer_value = answer.answer_value;
        let itemAnswer = { physique_id, question_id, answer_id, answer_value };
        this.selectedItems[index] = itemAnswer;
        this.$set(this.choosenIndex, index, answerIndex);
        this.$refs['item' + index][0].className = 'item'
      })
    },
    clickAnswer (answer, item, index, answerIndex) {
      let physique_id = item.physique_id;
      let question_id = item.id;
      let answer_id = answer.id;
      let answer_value = answer.answer_value;
      let itemAnswer = { physique_id, question_id, answer_id, answer_value };
      this.selectedItems[index] = itemAnswer;
      this.$set(this.choosenIndex, index, answerIndex);
      this.$refs['item' + index][0].className = 'item'
    },
    checkStatus () {

    },
    submitAnswer () {
      let arrLength = this.choosenIndex.length;
      let chooseLength = 0;
      this.choosenIndex.map((item, index) => {
        if (item != null) {
          ++chooseLength;
          this.$refs['item' + index][0].className = 'item'
        } else {
          //console.log(this.$refs['item'+index]);
          this.$refs['item' + index][0].className = 'item unseleted'
        }
      });
      //console.log(this.$refs);
      if (arrLength == chooseLength) {
        let applyid = this.$route.query.applyid;
        let idcard = this.$route.query.idcard;
        let items = this.selectedItems;
        let physique_type = this.$route.query.id;
        let data = { applyid, idcard, items, physique_type };
        let url = `/cmpa/questionnaire`;
        http.post(url, data)
          .then((res) => {
            if (res.code == "200") {
              let id = res.data;
              this.answerid = id;
              this.reset();
              this.get();
              this.$router.push({ 'path': '/medicalresult', query: { id, applyid, idcard } })
            }
          });
      } else {
        this.$toasted.show('请全部选择完后再提交');
      }

    }
  }
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: PingFangSC;
  color: rgba(103, 103, 103, 1);
  -webkit-text-size-adjust: none;
  background: rgba(249, 249, 251, 1);
}
.medical-test {
  width: 7.5rem;
  height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  h2 {
    width: 100%;
    height: 1rem;
    background: rgba(0, 198, 174, 1);
    font-size: 0.4rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 1rem;
  }
  .content {
    width: 6.7rem;
    height: calc(100vh - 3.6rem);
    background: rgba(255, 255, 255, 1);
    border-radius: 0.26rem;
    margin: 0.4rem auto 0;
    padding: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    .item {
      margin-bottom: 0.46rem;
      h3 {
        font-size: 0.22rem;
        font-weight: 500;
        color: rgba(69, 69, 69, 1);
        text-align: left;
      }
      .select-wrap {
        display: flex;
        justify-content: space-around;

        .select {
          width: 0.9rem;
          height: 0.4rem;
          margin-top: 0.3rem;
          background: rgba(249, 249, 249, 1);
          border-radius: 0.25rem;
          font-size: 0.18rem;
          font-weight: 400;
          color: rgba(103, 103, 103, 1);
          line-height: 0.4rem;
          &.active {
            background: rgba(0, 198, 174, 0.1);
            color: #00c6ae;
          }
        }
      }
      &.unseleted {
        border: 1px solid red;
      }
    }
  }
  .btn {
    width: 2.96rem;
    height: 0.8rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0.02rem 0.17rem 0px rgba(238, 238, 238, 1);
    border-radius: 0.45rem;
    line-height: 0.8rem;
    margin: 0 auto;
    font-size: 0.36rem;
    font-weight: 500;
    color: rgba(0, 198, 174, 1);
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    &.pause {
      color: rgba(214, 214, 214, 1);
    }
  }
}
</style>
